.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo-container {
    width: 210px;
    height: auto;
    margin-bottom: 15px;
}

.form-container {
    padding: 50px;
    width: 450px;
    border-radius: 13px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.login-title {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: 'Inter-Bold';
}

.input-container {
    width: 100%;
    margin-bottom: 20px;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.error-text {
    color: red;
    margin-bottom: 10px;
}

.login-button {
    width: 100%;
    padding: 12px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
}

.full-body {
    box-sizing: border-box;
    margin: 0;
    padding: -10px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden; /* Hide the overflow to prevent content blur */

    /* Set background image */
    /* background-image: url("../assets/bg-login.jpg"); */
    background-size: cover;
    background-position: center center;

    /* Add a pseudo-element for the background overlay */
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: inherit; /* Inherit background properties from the parent */
        filter: blur(10px); /* Adjust the blur amount as needed */
        z-index: -1; /* Move the pseudo-element below the content */
    }
}
