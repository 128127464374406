.container{
    padding-bottom: 10vh;
}

.top-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.leave-button{
    background-color: #0d6efd;
    color: white;
    padding: 5px 15px;
    border-color: #0d6efd;
}