.notification{
    display: flex;
   
    width: 100%;
    

    padding: 16px 10px;
    box-shadow: 0 1px 0 0 #e8ecf1;
    margin: 0px 10px;
}

.user-image{
    height: 50px;
    border: 2px solid black;
    border-radius: 50px;
    width: 50px;
    object-fit: cover;
}

.image-container{
    padding-right: 20px;
}
.user-name{
    font-weight: bold;
}

.message{
    font-weight: 500;
}


/* ======================= */
