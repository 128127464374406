.rotate-icon {
  transition: transform 0.3s ease-in-out;
}

.sb-sidenav-collapse-arrow {
  
  transform: rotate(0deg);
}

.down {
  transform: rotate(-90deg);
  
}

.active-link {
  color: white;
}
